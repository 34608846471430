(function() {
    'use strict';

    function getParamsFromUrl(url) {
        var splittedUrl = url.split('?');
        if (splittedUrl.length < 2) {
            return {};
        }

        var match,
            pl = /\+/g, // Regex for replacing addition symbol with a space
            search = /([^&=]+)=?([^&]*)/g,
            decode = function(s) { return decodeURIComponent(s.replace(pl, " ")); },
            query = splittedUrl[1],
            urlParams = {};

        while (match = search.exec(query)) {
            urlParams[decode(match[1])] = decode(match[2]);
        }
        return urlParams;
    }

    var hideBlocks = document.querySelectorAll('[data-hide-if]');
    hideBlocks.forEach(function(block) {
        var conditions = block.getAttribute('data-hide-if').split(',');
        conditions.forEach(function(condition) {
            if (window.location.href.indexOf(condition) > -1) {
                block.style.display = 'none';
            }
        })
    });

    var links = document.querySelectorAll('.js-offer-link[href*="${"]');
    var queryParams = getParamsFromUrl(window.location.href);

    links.forEach(function(link) {
        for (var paramName in queryParams) {
            if (queryParams.hasOwnProperty(paramName)) {
                var paramValue = queryParams[paramName];
                link.setAttribute('href', link.getAttribute('href').replace('${' + paramName + '}', paramValue));
            }
        }
        link.setAttribute('href', link.getAttribute('href').replace(/\$\{.*\}/gi, ''));
    });

}());